@charset "UTF-8";
/* vim: set ts=2 sw=2 sts=2 tw=0 expandtab nowrap */
body {
  font-size: 14px;
  color: #555;
  background-color: #fdd7b6;
  margin: 0px;
  padding: 0px;
  font-family: "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  line-height: 22px;
  word-break: break-all; }
  body a {
    color: #333;
    text-decoration: none; }
    body a:hover {
      text-decoration: underline; }

a img {
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
  border-style: none; }
  a img:hover {
    opacity: 0.7; }

#head {
  background-image: url(common/head_bg.gif);
  background-repeat: repeat-x;
  background-position: bottom;
  height: 140px; }
  #head .discription {
    font-size: 14px;
    line-height: 30px;
    color: #FFF;
    background-image: url(common/discription_bg.gif);
    background-repeat: repeat-x;
    height: 30px;
    text-align: center;
    min-width: 980px; }
  #head .toplogo {
    margin: 0px;
    padding: 0px;
    float: left; }
  #head .headinner {
    margin: auto;
    padding: 0 30px;
    width: 920px; }
    #head .headinner .toplink {
      text-align: right;
      padding: 22px 0px; }
      #head .headinner .toplink a {
        color: #604C41;
        text-decoration: none; }
    #head .headinner .tel {
      text-align: right; }
      #head .headinner .tel a img {
        height: 28px;
        margin: 0 0 0 10px; }

#gmenu {
  background-image: url(common/gmenu_bg.png);
  background-repeat: repeat-x;
  height: 80px;
  border-top: 1px solid #999;
  border-bottom: 1px solid #999; }
  #gmenu ul {
    width: 980px;
    height: 80px;
    margin: auto;
    padding: 0px;
    list-style-type: none;
    border-left: 1px solid #AAA; }
    #gmenu ul li {
      font-size: 1px;
      color: #FFF;
      display: block;
      float: left;
      width: 25%;
      overflow: hidden;
      transition-duration: 0.3s;
      transition-timing-function: ease-out;
      background-image: url(common/gmenu_service.png); }
      #gmenu ul li:hover {
        background-color: #FFFFFF;
        opacity: 0.8; }
      #gmenu ul li a {
        display: block;
        padding: 80px 0 0;
        height: 0px;
        border-right: 1px solid #AAA; }
    #gmenu ul .facilities {
      background-image: url(common/gmenu_facilities.png); }
    #gmenu ul .type {
      background-image: url(common/gmenu_type.png); }
    #gmenu ul .simulation {
      background-image: url(common/gmenu_simulation.png); }
    #gmenu ul .faq {
      background-image: url(common/gmenu_faq.png); }

#indeximg {
  background-color: #fdd7b6; }
  #indeximg .bxslider {
    margin: 0px;
    padding: 0px; }
  #indeximg .bx-wrapper .bx-viewport {
    margin: 0px auto;
    padding: 0px;
    border: none;
    left: 0px;
    max-width: 980px; }

#facilityimg {
  margin: 30px 0 0px;
  padding: 0px; }
  #facilityimg .slider2 {
    margin: 0px;
    padding: 0px; }
  #facilityimg .bx-wrapper .bx-viewport {
    margin: 0px;
    padding: 0px;
    border: none;
    left: 0px;
    box-shadow: none; }
  #facilityimg .bx-wrapper .bx-prev {
    left: -40px; }
  #facilityimg .bx-wrapper .bx-next {
    right: -40px; }

#wrap {
  margin: 0 auto 30px;
  padding: 30px 30px 0px;
  width: 920px;
  overflow: auto;
  background-image: url(common/wrap_bottom_bg.png);
  background-repeat: no-repeat;
  background-position: right bottom; }

#foot {
  margin: auto;
  padding: 20px 30px;
  background-color: #D99D47;
  min-width: 920px; }
  #foot .footlogo {
    color: white;
    font-size: 20px;
    font-weight: bold;
    float: left; }
    #foot .footlogo img {
      width: 240px; }
  #foot .addr {
    font-size: 14px;
    line-height: 24px;
    color: #FFF;
    margin: 0 0 0 270px;
    text-align: right; }

.shinobu {
  text-align: center;
  padding: 30px 30px 50px; }
  .shinobu .shinobutitle {
    font-size: 16px;
    line-height: 28px;
    font-weight: bold;
    color: #f55125; }
  .shinobu h1 {
    margin: 30px 0px; }
    .shinobu h1 img {
      width: 360px;
      max-width: 100%; }
  .shinobu .taiseimain img {
    width: 740px;
    max-width: 100%; }
  .shinobu p {
    text-align: left;
    margin: 30px 0; }
  .shinobu .bot a {
    color: #FFF;
    background-color: #00913A;
    display: inline-block;
    padding: 10px 20px;
    border-radius: 5px;
    transition-duration: 0.3s;
    transition-timing-function: ease-out; }
    .shinobu .bot a:hover {
      background-color: #9DD645;
      text-decoration: none; }

#wrap #content {
  float: left;
  width: 600px; }
  #wrap #content .indexbnr ul {
    margin: 0px;
    padding: 0px;
    list-style-type: none; }
    #wrap #content .indexbnr ul li {
      display: block;
      margin: 0 0 30px;
      padding: 0px; }
      #wrap #content .indexbnr ul li img {
        width: 600px; }
  #wrap #content .col .onsen_image img,
  #wrap #content .col .header_onsen img {
    width: 100%; }
  #wrap #content .col .b6010a {
    color: #b6010a; }
  #wrap #content .col h2 {
    border: 1px solid #CCBC99;
    border-bottom: 5px solid #604C41;
    padding: 10px 10px 8px;
    border-radius: 5px 5px 0 0;
    color: #604C41;
    background: -moz-linear-gradient(top, #f4f2ee 0%, #e6e3d9 100%);
    /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #f4f2ee), color-stop(100%, #e6e3d9));
    /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, #f4f2ee 0%, #e6e3d9 100%);
    /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, #f4f2ee 0%, #e6e3d9 100%);
    /* Opera 11.10+ */
    background: -ms-linear-gradient(top, #f4f2ee 0%, #e6e3d9 100%);
    /* IE10+ */
    background: linear-gradient(to bottom, #f4f2ee 0%, #e6e3d9 100%);
    /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f4f2ee', endColorstr='#e6e3d9',GradientType=0 );
    /* IE6-9 */
    font-size: 20px;
    line-height: 28px;
    margin: 0; }

#wrap #sidebar {
  width: 280px;
  float: right;
  overflow: hidden;
  margin: 0 0 120px; }
  #wrap #sidebar .tel a img {
    width: 280px; }
  #wrap #sidebar .scol {
    width: 264px;
    border: 8px solid #EFEDDF;
    margin: 20px 0 0;
    padding: 0px; }
    #wrap #sidebar .scol ul li a {
      word-break: break-all; }
    #wrap #sidebar .scol h2 {
      color: #FFF;
      background-color: #AA996D;
      border-bottom: 5px solid #604C41;
      margin: 0px;
      padding: 10px 10px 8px;
      background: #a59982;
      /* Old browsers */
      background: -moz-linear-gradient(top, #a59982 0%, #afa38e 100%);
      /* FF3.6+ */
      background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #a59982), color-stop(100%, #afa38e));
      /* Chrome,Safari4+ */
      background: -webkit-linear-gradient(top, #a59982 0%, #afa38e 100%);
      /* Chrome10+,Safari5.1+ */
      background: -o-linear-gradient(top, #a59982 0%, #afa38e 100%);
      /* Opera 11.10+ */
      background: -ms-linear-gradient(top, #a59982 0%, #afa38e 100%);
      /* IE10+ */
      background: linear-gradient(to bottom, #a59982 0%, #afa38e 100%);
      /* W3C */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a59982', endColorstr='#afa38e',GradientType=0 );
      /* IE6-9 */
      font-size: 18px;
      line-height: 24px; }
  #wrap #sidebar .news.scol ul {
    margin: 0px;
    padding: 0px;
    list-style-type: none;
    background-color: #EFEDDF; }
    #wrap #sidebar .news.scol ul li a {
      padding: 6px;
      display: block;
      border-bottom: 1px solid #AAA;
      transition-duration: 0.3s;
      transition-timing-function: ease-out;
      line-height: 18px; }
      #wrap #sidebar .news.scol ul li a:hover {
        text-decoration: none;
        background-color: #FAF8F4; }
      #wrap #sidebar .news.scol ul li a span {
        display: block; }
  #wrap #sidebar .faqlink.scol {
    margin: 0px;
    padding: 0px; }
    #wrap #sidebar .faqlink.scol ul {
      margin: 0px;
      padding: 0px;
      list-style-type: none;
      background-color: #EFEDDF; }
      #wrap #sidebar .faqlink.scol ul li a {
        padding: 10px 6px 10px 35px;
        display: block;
        border-bottom: 1px solid #AAA;
        transition-duration: 0.3s;
        transition-timing-function: ease-out;
        line-height: 18px;
        background-image: url(common/faqlink_arrow.png);
        background-repeat: no-repeat;
        background-position: 8px center; }
        #wrap #sidebar .faqlink.scol ul li a:hover {
          text-decoration: none;
          background-color: #FAF8F4; }
  #wrap #sidebar .price.scol {
    margin: 0 0 20px;
    padding: 0px; }
    #wrap #sidebar .price.scol dl {
      margin: 0;
      padding: 5px 0 0;
      list-style-type: none;
      background-color: #EFEDDF; }
      #wrap #sidebar .price.scol dl p {
        font-size: 13px;
        line-height: 18px;
        margin: 0;
        border-bottom: 1px solid #AAA;
        padding: 5px 0; }
      #wrap #sidebar .price.scol dl dd {
        padding: 6px;
        margin: 0;
        display: block;
        border-bottom: 1px solid #AAA;
        transition-duration: 0.3s;
        transition-timing-function: ease-out;
        line-height: 18px;
        text-align: right; }
      #wrap #sidebar .price.scol dl dt {
        padding: 6px;
        line-height: 18px;
        display: block;
        float: left; }
  #wrap #sidebar .point4 dl {
    margin: 0;
    background-color: white; }
    #wrap #sidebar .point4 dl dt {
      color: #FFF;
      background-color: #604C41;
      float: left;
      width: 22px;
      text-align: center;
      clear: left;
      margin: 8px;
      border-radius: 3px; }
    #wrap #sidebar .point4 dl dd {
      padding: 8px 8px 8px 40px;
      margin: 0px;
      border-bottom: 1px solid #CCC; }
  #wrap #sidebar #googleMap {
    width: 100%;
    height: 200px; }
  #wrap #sidebar .onsen img {
    width: 100%;
    margin-top: 20px; }
  #wrap #sidebar .yanagawataiseien img {
    width: 100%;
    margin-top: 20px; }
  #wrap #sidebar .iizaka img {
    width: 100%;
    margin-top: 20px; }

#pagetitle {
  background-image: url(common/pagetitlebg.png);
  background-repeat: repeat-x;
  height: 80px; }
  #pagetitle h1 {
    font-family: "ＭＳ Ｐ明朝", "MS PMincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", serif;
    font-size: 30px;
    line-height: 80px;
    color: #FFF;
    margin: auto;
    padding: 0 40px;
    width: 900px; }

#wrap #content .col {
  margin: 0 0 50px;
  padding: 0; }
  #wrap #content .col .collist {
    margin: 0px;
    padding: 0;
    list-style-type: none; }
    #wrap #content .col .collist li {
      padding: 30px 0 20px;
      border-bottom: 1px solid #CCC;
      overflow: auto;
      margin: 0; }
      #wrap #content .col .collist li h3 {
        font-size: 18px;
        line-height: 20px;
        margin: 0 0 10px;
        padding: 0px; }

.textbot a {
  display: inline-block;
  color: #FFF;
  margin: 0px;
  padding: 0 20px;
  height: 30px;
  background-color: #A59A82;
  line-height: 30px;
  text-align: center;
  transition-duration: 0.3s;
  transition-timing-function: ease-out; }
  .textbot a:hover {
    background-color: #5A4E3B;
    text-decoration: none; }

#wrap #content .col .collist li img {
  width: 600px;
  margin-bottom: 1em; }

#wrap #content .col .collist li .alignright {
  width: 200px; }

#wrap #sidebar .haikai img {
  width: 280px; }

#wrap #content .col .faq {
  margin: 10px 0 0px;
  padding: 0px;
  list-style-type: none; }
  #wrap #content .col .faq li {
    margin: 0px;
    padding: 0px;
    border-bottom: 1px solid #CCC; }
    #wrap #content .col .faq li h3 {
      font-size: 18px;
      line-height: 26px;
      background-image: url(common/faq_open.png);
      background-repeat: no-repeat;
      background-position: right 10px;
      margin: 0 0 12px;
      padding: 12px 80px 0 0;
      cursor: pointer; }
      #wrap #content .col .faq li h3.faqselected {
        background-image: url(common/faq_close.png); }

#wrap #content .col .contactform {
  border-top: 1px solid #CCC;
  margin: 0;
  font-size: 16px;
  line-height: 28px;
  width: 600px; }
  #wrap #content .col .contactform dt {
    float: left;
    clear: left;
    margin: 0px;
    padding: 12px 10px;
    vertical-align: top; }
  #wrap #content .col .contactform dd {
    margin: 0px;
    padding: 15px 10px 15px 160px;
    border-bottom: 1px solid #CCC;
    font-size: 16px;
    vertical-align: top;
    background: #FFF; }
    #wrap #content .col .contactform dd input {
      border: 1px solid #999;
      font-size: 14px;
      line-height: 20px; }
    #wrap #content .col .contactform dd textarea {
      border: 1px solid #999;
      font-size: 16px;
      line-height: 20px; }
  #wrap #content .col .contactform .form_error {
    background-color: #FDD; }
  #wrap #content .col .contactform .error {
    color: #D00; }
  #wrap #content .col .contactform .button {
    margin: 20px 0 0 0;
    padding: 0;
    text-align: center; }
  #wrap #content .col .contactform .csubmit {
    font-size: 14px;
    padding: 5px;
    margin: 0 5px;
    display: inline;
    border: 1px solid #AAA;
    background-color: #EEE;
    border-radius: 3px;
    width: 100px;
    text-align: center; }
    #wrap #content .col .contactform .csubmit:hover {
      background-color: #CCC; }

#wrap #content .col .simulationform {
  border-top: 1px solid #CCC;
  margin: 0 0 40px 0;
  font-size: 16px;
  line-height: 28px;
  width: 600px; }
  #wrap #content .col .simulationform dt {
    float: left;
    clear: left;
    margin: 0px;
    padding: 12px 10px;
    vertical-align: top; }
  #wrap #content .col .simulationform dd {
    margin: 0px;
    padding: 15px 10px 15px 160px;
    border-bottom: 1px solid #CCC;
    font-size: 16px;
    vertical-align: top;
    text-align: right;
    background: #FFF; }
    #wrap #content .col .simulationform dd #rank {
      margin: 2px 0;
      border: 1px solid #999;
      font-size: 14px;
      line-height: 20px;
      width: 120px; }
    #wrap #content .col .simulationform dd #rent_fee {
      margin: 2px 0;
      border: 1px solid #999;
      font-size: 14px;
      line-height: 20px;
      width: 160px; }
    #wrap #content .col .simulationform dd#total {
      font-weight: bold;
      color: #dd0000; }

#wrap #content .col .simulation_care {
  border-top: 1px solid #CCC;
  margin: 0;
  font-size: 16px;
  line-height: 28px;
  width: 600px; }
  #wrap #content .col .simulation_care dt, #wrap #content .col .simulation_care dd {
    display: block;
    float: left;
    height: 30px; }
  #wrap #content .col .simulation_care dt {
    margin: 0px;
    padding: 12px 10px;
    border-bottom: 1px solid #CCC;
    vertical-align: top;
    width: 100px;
    background: #FFF; }
  #wrap #content .col .simulation_care dd {
    margin: 0px;
    padding: 12px 10px;
    border-bottom: 1px solid #CCC;
    font-size: 16px;
    vertical-align: top;
    text-align: right;
    width: 160px;
    background: #FFF; }

.clearfix:after {
  content: "";
  clear: both;
  display: block; }

#wrap #content .col .datalist {
  border-top: 1px solid #CCC;
  margin: 0; }
  #wrap #content .col .datalist dt {
    float: left;
    clear: left;
    margin: 0px;
    padding: 15px 10px;
    width: 150px; }
  #wrap #content .col .datalist dd {
    margin: 0px;
    padding: 15px 10px 15px 160px;
    border-bottom: 1px solid #CCC;
    background: #FFF; }

.shinobu .bosyumain img {
  width: 400px; }

.shinobu .room50 img {
  margin: 0 auto 20px;
  padding: 0px;
  width: 180px; }

.shinobu .bosyu {
  border: 2px solid #393323;
  color: #393323;
  display: inline-block;
  font-size: 40px;
  line-height: 50px;
  width: 400px;
  margin: 30px auto 20px;
  padding: 0px; }

.shinobu dl {
  text-align: left;
  border-top: 1px solid #CCC;
  margin: 40px 0; }
  .shinobu dl p {
    margin: 0px;
    padding: 10px 0; }
  .shinobu dl dt {
    float: left;
    color: #FFF;
    background-color: #393323;
    font-size: 16px;
    line-height: 26px;
    width: 26px;
    text-align: center;
    margin: 10px 0 0 10px;
    border-radius: 3px; }
  .shinobu dl dd {
    font-size: 20px;
    line-height: 26px;
    margin: 0px;
    padding: 10px 10px 10px 50px;
    border-bottom: 1px solid #CCC; }

.shinobu .tel a img {
  width: 280px;
  margin: 20px 0 40px; }

.shinobu .bot.cform a {
  background-color: #4E4631; }
  .shinobu .bot.cform a:hover {
    background-color: #393323; }

img.alignright {
  float: right;
  margin: 0 0 10px 10px;
  padding: 0px; }

img.alignleft {
  float: left;
  margin: 0 10px 10px 0;
  padding: 0px; }

.room-charge-table .reserved, .room-charge-table .occupied {
  background-color: #FFFECD; }

.room-charge-table .vacancy {
  background-color: #D8ECED; }

.room-charge-table .blank {
  background-color: white; }

.room-charge-table table {
  width: 100%;
  border: 1px solid black;
  border-collapse: collapse;
  margin-bottom: 1rem;
  font-size: 0.7rem;
  line-height: 1.2em;
  text-align: center;
  background-color: white; }
  .room-charge-table table tr.row-3 {
    border-top: 3px double black; }
  .room-charge-table table th, .room-charge-table table td {
    border: 1px solid black;
    padding: 0.5rem; }
    .room-charge-table table th.price-table__cell--tamokuteki, .room-charge-table table td.price-table__cell--tamokuteki {
      font-size: 0.6rem; }
  .room-charge-table table th:first-child {
    width: 3rem; }

.room-charge-table table.room-charge__legend--vacancy {
  float: left;
  width: auto;
  margin-right: 1rem; }

.room-charge-table table.room-charge__legend--room {
  float: left;
  width: auto;
  border: 1px solid black;
  margin-bottom: 1rem;
  border-bottom: none;
  font-size: 0.7rem;
  background-color: #D8ECED; }
  .room-charge-table table.room-charge__legend--room .legend__room-number {
    border-bottom: none; }

.room-charge-table table.simulation__price-table--3F {
  table-layout: fixed; }
  .room-charge-table table.simulation__price-table--3F td {
    width: 80%; }
